import { Row, Col, Image } from "react-bootstrap";
import {
  DevOpsImage,
  AppOps,
  Offshore,
  Auditing,
  Automation,
} from "../../assets/img";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const Services = () => {
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  const animationVariantsForImagesLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const animationVariantsForImagesRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <main id="services">
      <Row className="align-items-center pt-5 gx-5">
        <Col
          md={{ span: "auto", order: "first" }}
          xs={{ span: "auto", order: "last" }}
        >
          <InView triggerOnce>
            {({ inView, ref }) => (
              <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={animationVariantsForImagesLeft}
                transition={{
                  duration: 0.5,
                  delay: 0.2,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
              >
                <Image src={DevOpsImage} fluid />
              </motion.div>
            )}
          </InView>
        </Col>
        <Col>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                  className="fw-bold mt-4"
                >
                  Fullstack Dev Pods
                </motion.h3>
                <motion.p
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.3,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  A managed capacity solution that accelerates product roadmaps
                  and delivery <br />
                  timelines.
                </motion.p>
              </>
            )}
          </InView>
        </Col>
      </Row>

      <Row className="align-items-center mt-5 gx-5">
        <Col>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.4,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                  className="fw-bold mt-4"
                >
                  Continuous App Operations
                </motion.h3>
                <motion.p
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.5,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  Support to maintain your applications with zero knowledge loss
                  and <br />
                  continuous operations processes so you can focus on building
                  net new <br />
                  features.
                </motion.p>
              </>
            )}
          </InView>
        </Col>
        <Col md={"auto"}>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.div
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariantsForImagesRight}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  <Image src={AppOps} fluid />
                </motion.div>
              </>
            )}
          </InView>
        </Col>
      </Row>

      <Row className="align-items-center mt-5 gx-5">
        <Col
          md={{ span: "auto", order: "first" }}
          xs={{ span: "auto", order: "last" }}
        >
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.div
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariantsForImagesLeft}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  <Image src={Offshore} fluid />
                </motion.div>
              </>
            )}
          </InView>
        </Col>
        <Col>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.3,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                  className="fw-bold mt-4"
                >
                  Offshore Staffing
                </motion.h3>
                <motion.p
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.4,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  Global recruiting and staffing for you to build your own
                  offshore delivery center.
                </motion.p>
              </>
            )}
          </InView>
        </Col>
      </Row>

      <Row className="align-items-center mt-5 gx-5">
        <Col>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.3,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                  className="fw-bold mt-4"
                >
                  Audit and Assessment
                </motion.h3>
                <motion.p
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.4,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  A thorough evaluation of an organization's IT infrastructure
                  and processes.
                  <br />
                  We identify vulnerabilities, assess risks, and provide
                  recommendations to
                  <br />
                  enhance security and compliance.
                </motion.p>
              </>
            )}
          </InView>
        </Col>
        <Col md="auto">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.div
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariantsForImagesLeft}
                  transition={{
                    duration: 0.5,
                    delay: 0.3,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  <Image src={Auditing} fluid />
                </motion.div>
              </>
            )}
          </InView>
        </Col>
      </Row>

      <Row className="align-items-center mt-5 gx-5">
        <Col
          md={{ span: "auto", order: "first" }}
          xs={{ span: "auto", order: "last" }}
        >
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.div
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariantsForImagesRight}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  <Image src={Automation} fluid />
                </motion.div>
              </>
            )}
          </InView>
        </Col>
        <Col>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <motion.h3
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.4,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                  className="fw-bold mt-4"
                >
                  Automation Engineering
                </motion.h3>
                <motion.p
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={animationVariants}
                  transition={{
                    duration: 0.5,
                    delay: 0.5,
                    type: "spring",
                    damping: 10,
                    stiffness: 100,
                  }}
                >
                  Designing and implementing a tailored automated solutions for
                  increased efficiency and streamlined processes across
                  industries.
                </motion.p>
              </>
            )}
          </InView>
        </Col>
      </Row>
    </main>
  );
};

export default Services;
