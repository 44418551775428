/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LogoWhite } from "../../assets/img";
import { TwitterLogo, LinkedInLogo } from "../../assets/icons";

const Footer = () => {
  return (
    <footer id="footer-area" className="bg-dark py-5">
      <Container>
        <Row className="gy-5">
          <Col lg={4}>
            <Image src={LogoWhite} />
          </Col>
          <Col lg={8} md={12} className="">
            <Row>
              <Col md={4} sm={4} xs={12} className="Footer-links">
                <span className="text-white fs-5 fw-bold">What we do</span>
                <ul className="list-unstyled mt-4 text-white">
                  <li>Full stack Dev Pods</li>
                  <li>Continuous App Operations</li>
                  <li>Offshore Staffing</li>
                  <li>Audit and Assessment</li>
                  <li>Automation Engineering</li>
                </ul>
              </Col>
              <Col md={4} sm={4} xs={12} className="Footer-links">
                <span className="text-white fs-5 fw-bold">Company</span>
                <ul className="list-unstyled mt-4">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <a
                  href="https://www.linkedin.com/company/juduh/"
                  target="_blank"
                >
                  <img src={LinkedInLogo} className="footer-socials" alt="" />
                </a>
                <a
                  href="https://x.com/juduhtech?s=21&t=B6LtVqPtgjhQfGYE5NilRw"
                  target="_blank"
                >
                  <img
                    src={TwitterLogo}
                    className="footer-socials mx-3"
                    alt=""
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>

        <Row>
          <Col>
            <p className="text-white mt-4 fs-6">
              Copyright © 2024. All rights reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
