import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const BottomHero = ({ buttonLabel }) => {
  const navigate = useNavigate();
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section id="bottom-hero-section">
      <Container className="py-5">
        <Row className="align-items-center">
          <InView triggerOnce>
            {({ inView, ref }) => (
              <>
                <Col className="text-center text-white">
                  <motion.h1
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={animationVariants}
                    transition={{ duration: 0.2 }}
                    className="fw-bold"
                  >
                    Ready to redefine brilliance?
                  </motion.h1>
                  <motion.p
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={animationVariants}
                    transition={{ duration: 0.3 }}
                    className="mt-3"
                  >
                    Whether you're a startup dreaming big or a brand ready for a
                    digital revolution, Juduh is your partner in brilliance.
                    <br />
                    Let's embark on a journey where innovation knows no bounds.
                    Start your digital transformation with Juduh.
                  </motion.p>

                  <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={animationVariants}
                    transition={{ duration: 0.4 }}
                  >
                    <Button
                      variant="light"
                      className="py-3 px-3 mt-5"
                      onClick={() => navigate("/contact")}
                    >
                      {buttonLabel}
                    </Button>
                  </motion.div>
                </Col>
              </>
            )}
          </InView>
        </Row>
      </Container>
    </section>
  );
};

export default BottomHero;
