import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Hero } from "../components";
import { CareerLeading } from "../../assets/img";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const CareersPage = () => {
  const navigate = useNavigate();
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  const animationVariantsForImagesLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <main id="careers">
      <Hero>
        <motion.h1
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="display-2 fw-bold"
        >
          <span className="text-primary">Opportunity</span> created,
          <br />
          <span className="text-primary">potential</span> unleashed.
        </motion.h1>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
            delay: 0.5,
          }}
        >
          <Button
            className="p-3"
            variant="dark"
            onClick={() => navigate("/about")}
          >
            Learn more about us
          </Button>
        </motion.div>
      </Hero>

      <section>
        <Container className="mt-5">
          <Row className="gx-5">
            <Col md="auto">
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariantsForImagesLeft}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      <Image src={CareerLeading} fluid />
                    </motion.div>
                  </>
                )}
              </InView>
            </Col>
            <Col>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p className="fs-6 fw-normal">
                      Our culture and team are built on a foundation of respect,
                      trust, gratitude and inspiration. We strive to ensure
                      these qualities are reflected across Juduh in every
                      aspect.
                    </motion.p>
                  </>
                )}
              </InView>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p className="fs-6 fw-normal">
                      We love to work with incredible individuals who want to
                      make an impact. Join our growing team today and unleash
                      your potential.
                    </motion.p>
                  </>
                )}
              </InView>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="form-area">
        <Container className="py-5">
          <Row>
            <Col>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.h3
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="fw-bold"
                    >
                      Join our team.
                    </motion.h3>
                  </>
                )}
              </InView>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      We love working with amazing people who want to make an
                      impact.
                    </motion.p>
                  </>
                )}
              </InView>
              <Form className="mt-4 pt-5">
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Full name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your full name"
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Department</Form.Label>
                    <Form.Select defaultValue="-1">
                      <option value="-1" disabled>
                        Select department
                      </option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Role</Form.Label>{" "}
                    <Form.Select defaultValue="-1">
                      <option value="-1" disabled>
                        Select role
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Attach resume</Form.Label>
                    <Form.Control type="file" placeholder="Enter email" />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group tag={Col}>
                    <Button variant="dark" className="p-3 mt-4">
                      Submit application
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default CareersPage;
