import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { BottomHero, Hero } from "../components";
import MapImage from "../../assets/img/map.png";
import {
  RocketIcon,
  CheckmarkIcon,
  ChartLineIcon,
  BulbIcon,
  EditIcon,
  TargetIcon,
} from "../../assets/icons";
import { ProfileDavid, AboutLeading } from "../../assets/img";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const AboutPage = () => {
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  const animationVariantsHeader = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <section id="about">
      <Hero>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <>
              <motion.h1
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={animationVariantsHeader}
                transition={{
                  duration: 0.2,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
                className="display-2 fw-bold"
              >
                Creating a <span className="text-primary">global impact</span>
                <br />
                with our story.
              </motion.h1>

              <motion.h5
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={animationVariantsHeader}
                transition={{
                  duration: 0.2,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
                className="my-4 fw-normal"
              >
                At Juduh, we are committed to creating an impactful and global
                footprint using our <br />
                software solutions to drive growth for clients and create tech
                opportunities for <br />
                underutilized communities.
              </motion.h5>

              <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={animationVariants}
                transition={{
                  duration: 0.5,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  delay: 0.5,
                }}
              >
                <Button className="px-5 py-3" variant="dark">
                  What we do
                </Button>
              </motion.div>
            </>
          )}
        </InView>
      </Hero>

      <section className="bg-light">
        <Container className="py-5">
          <Row className="align-items-center">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <>
                  <Col>
                    <motion.h3
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.2,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      Our Mission
                    </motion.h3>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.2,
                        delay: 0.3,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      Technology is an incredibly powerful tool in today’s world
                      of business that can often overlook entire populations of
                      incredible global talent simply because of where they were
                      born. 
                    </motion.p>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.2,
                        delay: 0.4,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      We exist to change this and are driven to bridge the
                      diversity gap between talent and opportunity in the tech
                      space by bringing opportunities to those communities
                    </motion.p>
                  </Col>
                  <Col md={"auto"}>
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      <Image src={AboutLeading} fluid />
                    </motion.div>
                  </Col>
                </>
              )}
            </InView>
          </Row>
        </Container>
      </section>

      <section id="services-section">
        <Container className="py-5">
          <Row>
            <Col>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.h4
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="my-4 text-center"
                    >
                      Creating a global footprint that delivers quality services
                      to our clients and brings <br />
                      opportunities to communities.
                    </motion.h4>
                  </>
                )}
              </InView>

              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      <Image className="mt-5" src={MapImage} fluid />
                    </motion.div>
                  </>
                )}
              </InView>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="mt-4">Our Values</h3>
              <Row className="row-cols-md-3 row-cols-1 gy-5 gx-5 mt-2">
                <Col>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <>
                        <motion.div
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          <Image src={RocketIcon} width={32} />
                        </motion.div>

                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.3,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="mt-4 mb-3"
                        >
                          Ship fast
                        </motion.h5>
                        <motion.p
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.4,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          Speed of change in technology and innovation is
                          growing exponentially. We believe shipping functional
                          products fast is key to not just winning, but
                          surviving.
                        </motion.p>
                      </>
                    )}
                  </InView>
                </Col>

                <Col>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <>
                        <motion.div
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          <Image src={CheckmarkIcon} width={32} />
                        </motion.div>

                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="mt-4 mb-3"
                        >
                          Solve problems
                        </motion.h5>
                        <motion.p
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          Understand client needs. Build trust. Complex problems
                          deserve simple solutions. Execute.
                        </motion.p>
                      </>
                    )}
                  </InView>
                </Col>

                <Col>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <>
                        <motion.div
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          <Image src={ChartLineIcon} width={32} />
                        </motion.div>
                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.3,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="mt-4 mb-3"
                        >
                          Ruthless efficiency
                        </motion.h5>
                        <motion.p
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          Get it right the first time. Quality builds from the
                          start means faster delivery.
                        </motion.p>
                      </>
                    )}
                  </InView>
                </Col>

                <Col>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <>
                        <motion.div
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          <Image src={BulbIcon} width={32} />
                        </motion.div>
                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.3,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="mt-4 mb-3"
                        >
                          Be different
                        </motion.h5>
                        <motion.p
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.4,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          Question everything. Embrace diverse opinions and
                          thoughts. We all come from different places and
                          backgrounds, that is our strength.
                        </motion.p>
                      </>
                    )}
                  </InView>
                </Col>

                <Col>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <>
                        <motion.div
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.2,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          <Image src={EditIcon} width={32} />
                        </motion.div>
                      </>
                    )}
                  </InView>
                  <InView triggerOnce>
                    {({ inView, ref }) => (
                      <>
                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.3,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="mt-4 mb-3"
                        >
                          Create things
                        </motion.h5>
                        <motion.p
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.4,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                        >
                          Bring ideas to life. Try something new. Build cool
                          shit.
                        </motion.p>
                      </>
                    )}
                  </InView>
                </Col>

                <Col>
                  <Image src={TargetIcon} />
                  <h5 className="mt-4 mb-3">On a mission</h5>
                  <p>
                    Connecting opportunities to people. Solving big problems and
                    unlocking a new frontier of talent. What we’re doing
                    matters.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="our-team-section">
        <Container className="py-5">
          <Row>
            <Col
              md={{ span: "auto", order: "first" }}
              xs={{ span: "auto", order: "last" }}
            >
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.div
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      <Image className="rounded" src={ProfileDavid} fluid />
                    </motion.div>
                  </>
                )}
              </InView>
            </Col>

            <Col>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.h3
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.3,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      Our Team
                    </motion.h3>
                  </>
                )}
              </InView>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.3,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      As an  entrepreneur, David has always been passionate and
                      driven to create new things and is the visionary and
                      driving force behind Juduh. 
                    </motion.p>
                  </>
                )}
              </InView>

              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.4,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                    >
                      Together with his team, Juduh has over 50+ years of
                      combined experience creating and bringing ideas to life.
                    </motion.p>
                  </>
                )}
              </InView>

              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.5,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="fst-italic"
                    >
                      “Everybody has the potential to make a difference. At
                      Juduh, we connect highly skilled, hard-working tech teams
                      in Africa with customers around the U.S. and the world.
                      It’s a win-win we can all feel good about.”
                    </motion.p>
                  </>
                )}
              </InView>

              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.5,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="fw-bold"
                    >
                      - David Manly, Founder & CEO{" "}
                    </motion.p>
                  </>
                )}
              </InView>
            </Col>
          </Row>
        </Container>
      </section>

      <BottomHero buttonLabel="Lets work together" />
    </section>
  );
};

export default AboutPage;
