import { Container, Row, Col } from "react-bootstrap";
import { BottomHero, Hero, Services } from "../components";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const ServicesPage = () => {
  const animationVariantsHeader = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <main id="services">
      <Hero>
        <InView triggerOnce>
          {({ inView, ref }) => (
            <>
              <motion.h1
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={animationVariantsHeader}
                transition={{
                  duration: 0.2,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
                className="display-2 fw-bold"
              >
                <span className="text-primary">Build</span> right,
                <br />
                <span className="text-primary">ship</span> fast.
              </motion.h1>

              <motion.h5
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={animationVariantsHeader}
                transition={{
                  duration: 0.2,
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                }}
                className="my-4 fw-normal"
              >
                We provide premium solutions focused on software architecture,
                infrastructure, cloud
                <br />
                and engineering services through our delivery centers in the
                U.S. and Africa.
              </motion.h5>
            </>
          )}
        </InView>
      </Hero>

      <section id="service-count-section">
        <Container className="py-5 text-center">
          <Row>
            <Col className="border-end">
              <motion.h1
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
                className="display-3 fw-bold"
              >
                3,000+
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
              >
                software engineers accessed through
                <br /> our global network
              </motion.p>
            </Col>
            <Col className="border-end">
              <motion.h1
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
                className="display-3 fw-bold"
              >
                6-hr
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
              >
                time difference between
                <br />
                Ghana and CST teams
              </motion.p>
            </Col>
            <Col>
              <motion.h1
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
                className="display-3 fw-bold"
              >
                100%
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
              >
                engineers with English
                <br />
                as first language
              </motion.p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="services-section">
        <Container className="py-5">
          <Row>
            <Col>
              <h2 className="my-4 text-center fw-bold">Our Services</h2>
              <Services />
            </Col>
          </Row>
        </Container>
      </section>

      <BottomHero buttonLabel="Talk to us" />
    </main>
  );
};

export default ServicesPage;
