import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Hero } from "../components";
import { motion } from "framer-motion";

const ContactPage = () => {
  return (
    <main id="contact">
      <Hero>
        <motion.h1
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="display-3 fw-bold"
        >
          Lets work <span className="text-primary">together</span>
        </motion.h1>

        <motion.h5
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="my-4 fw-normal"
        >
          Get in touch with us today
        </motion.h5>
      </Hero>

      <section id="form-area">
        <Container className="py-5">
          <Row>
            <Col>
              <motion.h3
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  duration: 0.5,
                }}
                className="fw-bold"
              >
                Send us a message
              </motion.h3>
              <Form className="mt-5">
                <Row className="gy-3 row-md-cols-2">
                  <Form.Group as={Col} md="6">
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text" placeholder="First name" />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="text" placeholder="Last name" />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder="Enter your phone number"
                    />
                  </Form.Group>
                </Row>

                <Row className="mt-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3 mt-5">
                  <Form.Group tag={Col}>
                    <Button variant="dark" className="p-3 mt-4">
                      Submit Message
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default ContactPage;
