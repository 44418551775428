import {
  HomePage,
  ContactPage,
  CareersPage,
  AboutPage,
  ServicesPage,
} from "./presentation/pages";

const AppRoutes = [
  {
    index: true,
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/careers",
    element: <CareersPage />,
  },
  {
    path: "/services",
    element: <ServicesPage />,
  },
  // {
  //   path: '/fetch-data',
  //   requireAuth: true,
  //   element: <FetchData />
  // },
  // ...ApiAuthorzationRoutes
];

export default AppRoutes;
