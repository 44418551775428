import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

export class Hero extends Component {
  static displayName = Hero.name;

  render() {
    return (
      <section id="hero-section">
        <Container>
          <Row className="min-vh-85 align-items-center">
            <Col>{this.props.children}</Col>
          </Row>
        </Container>
      </section>
    );
  }
}
