import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { BottomHero, Hero, Services } from "../components";
import { FrSecure, MinilithLogo, CyferLogo } from "../../assets/img";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <main id="home">
      <Hero>
        <motion.h1
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="display-2 fw-bold"
        >
          Your innovation partner in
          <br />
          <span className="text-primary">custom software solutions.</span>
        </motion.h1>

        <motion.h5
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
          }}
          className="my-4 fw-normal"
        >
          Scaling your business without compromise.
        </motion.h5>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            damping: 10,
            stiffness: 100,
            duration: 0.5,
            delay: 0.5,
          }}
        >
          <Button
            className="px-5 py-3"
            variant="dark"
            onClick={() => navigate("/services")}
          >
            Services
          </Button>
        </motion.div>
      </Hero>

      <section id="services-section">
        <Container className="py-5">
          <Row>
            <Col>
              <InView triggerOnce>
                {({ inView, ref }) => (
                  <>
                    <motion.h4
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="my-4 text-center"
                    >
                      Juduh is a pioneering software engineering firm that
                      offers custom, innovative and strategic services to
                      address our client's needs in the ever-changing tech
                      market today.
                    </motion.h4>

                    <motion.p
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="my-5 text-center"
                    >
                      Empowering Innovation through Cutting-Edge Tech Solutions
                      and services.
                    </motion.p>
                  </>
                )}
              </InView>

              <Services />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="testimonials-section">
        <Container className="py-5">
          <Row>
            <InView triggerOnce>
              {({ inView, ref }) => (
                <>
                  <Col>
                    <motion.h2
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="text-center"
                    >
                      Trusted by leading voices and brands
                    </motion.h2>

                    <Card className="mt-4">
                      <Card.Body className="p-5">
                        <motion.h6
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.3,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="fst-italic"
                        >
                          “FRSecure is well-known in the infosec community as a
                          mission-driven organization and everything we do is
                          driven by our focus on fixing the broken information
                          security industry. As we navigated an expansion of our
                          mission into the attack surface space, we looked for a
                          software development partner that had the technical
                          horsepower we require and were mission-driven
                          themselves. Juduh was the clear choice based on that
                          criteria and it has been evident since day one of our
                          engagement. They are demonstrating a technical
                          proficiency far beyond what we expected and, more
                          importantly, understand why we are asking them to
                          build this product. The results have been beyond our
                          expectations on every level as a result of our
                          alignment.”
                        </motion.h6>
                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.4,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="text-end mt-3"
                        >
                          John Harmon <br />
                          <small className="fw-light fs-6">
                            CEO, FR Secure
                          </small>
                        </motion.h5>
                      </Card.Body>
                    </Card>

                    <Card className="mt-5">
                      <Card.Body className="p-5">
                        <motion.h6
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.3,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="fst-italic"
                        >
                          "Our goal was to find a software partner that could
                          understand and share in our passion and vision for our
                          project. From the beginning, Juduh made a roadmap for
                          every stage of the project, and has dedicated regular
                          time and resources so that we can see progress every
                          week. We are building the business of our dreams, and
                          Juduh is building it with us."
                        </motion.h6>
                        <motion.h5
                          ref={ref}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={animationVariants}
                          transition={{
                            duration: 0.5,
                            delay: 0.4,
                            type: "spring",
                            damping: 10,
                            stiffness: 100,
                          }}
                          className="text-end mt-3"
                        >
                          Janey Rizvi
                          <br />
                          <small className="fw-light fs-6">
                            Co-Founder, Minilith
                          </small>
                        </motion.h5>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              )}
            </InView>
          </Row>
        </Container>
      </section>

      <section id="clientele-section">
        <Container className="py-5">
          <Row>
            <InView triggerOnce>
              {({ inView, ref }) => (
                <>
                  <Col>
                    <motion.h2
                      ref={ref}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={animationVariants}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                      }}
                      className="text-center"
                    >
                      Our clientele ranges from cybersecurity to educational
                      tech and
                      <br />
                      financial services industries.
                    </motion.h2>

                    <div className="d-flex justify-content-center mt-5">
                      <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={animationVariants}
                        transition={{
                          duration: 0.5,
                          delay: 0.3,
                          type: "spring",
                          damping: 10,
                          stiffness: 100,
                        }}
                        className="clintele-logo-container me-5"
                      >
                        <Image
                          src={FrSecure}
                          className="clientele-logo"
                          fluid
                        />
                      </motion.div>
                      <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={animationVariants}
                        transition={{
                          duration: 0.5,
                          delay: 0.3,
                          type: "spring",
                          damping: 10,
                          stiffness: 100,
                        }}
                        className="clintele-logo-container"
                      >
                        <Image
                          src={MinilithLogo}
                          fluid
                          className="clientele-logo"
                        />
                      </motion.div>
                      <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={animationVariants}
                        transition={{
                          duration: 0.5,
                          delay: 0.3,
                          type: "spring",
                          damping: 10,
                          stiffness: 100,
                        }}
                        className="clintele-logo-container"
                      >
                        <Image
                          src={CyferLogo}
                          fluid
                          className="clientele-logo"
                        />
                      </motion.div>
                    </div>
                  </Col>
                </>
              )}
            </InView>
          </Row>
        </Container>
      </section>

      <BottomHero buttonLabel="Talk to us" />
    </main>
  );
};

export default HomePage;
