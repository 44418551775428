import React, { Component } from "react";
import { Header, Footer } from "../components";

export class MainLayout extends Component {
  static displayName = MainLayout.name;

  render() {
    return (
      <>
        <Header />
        {this.props.children}
        <Footer />
      </>
    );
  }
}
