import { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Offcanvas,
  Nav,
  Button,
  Image,
} from "react-bootstrap";
import JuduhLogo from "../../assets/img/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [offCanvasVisible, setOffCanvasVisible] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const menu = (
    <>
      <Nav className="justify-content-end flex-grow-1">
        <Nav.Link
          as={Link}
          to="/about"
          onClick={() => setOffCanvasVisible(false)}
        >
          About us
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/services"
          onClick={() => setOffCanvasVisible(false)}
        >
          Services
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/careers"
          onClick={() => setOffCanvasVisible(false)}
        >
          Careers
        </Nav.Link>
      </Nav>
      <Button
        variant="primary"
        className="px-4 py-2 mt-lg-0 mt-3"
        onClick={() => {
          setOffCanvasVisible(false);
          navigate("/contact");
        }}
      >
        Talk to us
      </Button>
    </>
  );

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image src={JuduhLogo} />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="topBar"
          onClick={() => setOffCanvasVisible(!offCanvasVisible)}
        />
        <Navbar.Offcanvas
          id="topBar"
          aria-labelledby="topBarLabel"
          placement="end"
          show={offCanvasVisible}
          onHide={() => setOffCanvasVisible(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="topBarLabel">
              <Image src={JuduhLogo} fluid />
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body>{menu}</Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
